import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {config, config_url } from "./config/Config";

fetch(config_url).then(async response => {
  config.config = await response.json();
  return <React.StrictMode><App /></React.StrictMode>;
}).catch(e => {
  return <div className="text-danger text-center w-100 my-1" aria-alert="true"><strong>Something's gone wrong. It's not your fault. Please try again.</strong></div>
}).then(app => {
  ReactDOM.render(
    app,
    document.getElementById('root')
  );
  
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();