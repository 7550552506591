import { Col, Container, Row, Button } from "react-bootstrap";
import Contact from "./cta/Contact";
import Donate from "./cta/Donate";

const MAX_WIDTH = "1000px";

function MenuItem({ name, active, handleOnClick }) {
    return (
        <li className={"nav-link fw-bold d-flex align-items-center " + (active ? "active" : "")} role="menuitem">
            <span className="foa-desktop w-100 text-center">
                <a href="#" style={{ textDecoration: "none" }} onClick={() => handleOnClick(name)}>{name}</a>
            </span>
            <span className="h1 text-center w-100 foa-mobile">
                {active ? <span className="text-info" role="none">{"> "}</span> : null}
                <a
                    href="#"
                    onClick={() => handleOnClick(name)} className={active ? "link-info" : ""}
                    style={{ textDecoration: "none" }}
                >
                    {name}
                </a>
                {active ? <span className="text-info" role="none">{" <"}</span> : null}
            </span>
        </li>
    );
}

export default function Menu({ pages, activePageIdx, handlePageSelect }) {
    function toggleMobileMenu(visible = undefined) {
        const [on, off] = ["100%", "0%"];
        const menu = document.querySelector(".foa-menu-mobile");
        const toggle = document.querySelector(".foa-menu-mobile-toggle");
        if(visible || menu.style.maxWidth === off || !menu.style.maxWidth) {
            menu.style.maxWidth = on;
            menu.setAttribute("aria-hidden", "false");
            toggle.setAttribute("aria-expanded", "true");
        } else {
            menu.style.maxWidth = off;
            menu.setAttribute("aria-hidden", "true");
            toggle.setAttribute("aria-expanded", "false");
        }
    }

    pages = pages.map((name, idx) => <MenuItem key={name} name={name} active={idx === activePageIdx} handleOnClick={page => { handlePageSelect(page); toggleMobileMenu(false); }} />)
    const classes = "nav"
    return (
        <Container className="p-0 m-0 w-100" fluid>
            <Row>
                <Col className="d-flex p-0 my-3">
                    <div className="flex-grow-1 d-flex align-items-center h-100 ms-3">
                        <img
                            alt=""
                            src="/favicon.ico"
                            className="d-inline-block align-top"
                        />&nbsp;
                        <h5 className="p-0 m-0">flags of asexuality</h5>
                    </div>
                    <div className="foa-mobile">
                        <Button
                            onClick={() => toggleMobileMenu()}
                            className="bg-transparent border-0 text-black fs-1 foa-menu-mobile-toggle"
                            style={{ zIndex: 1 }}
                            aria-label="Menu"
                            aria-controls="foa-menu-mobile"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="bi bi-list"></i>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <nav role="navigation">
                        <ul role="menu" className={[
                            classes,
                            "nav-tabs nav-fill",
                            "foa-desktop foa-menu"
                        ].join(" ")}
                        >
                            {pages}
                        </ul>
                        <ul
                            role="menu"
                            id="foa-menu-mobile"
                            className={[
                                classes,
                                "d-flex flex-column bg-white position-absolute end-0",
                                "foa-mobile foa-menu foa-menu-mobile"
                            ].join(" ")}
                            aria-hidden="true"
                            aria-label="Main menu."
                        >
                            {pages}
                            <span className="border-top pt-2 flex-grow-1" role="none">
                                <Container className="m-0 p-0" role="none" fluid>
                                    <Row role="none">
                                        <Col className="col-6 h1 m-0 p-0 text-center fw-bold" role="none">
                                            <Contact as={
                                                handleClick => <a
                                                    href="#"
                                                    onClick={() => { handleClick(); toggleMobileMenu(false); }}
                                                    className="text-decoration-none link-secondary"
                                                    role="menuitem"
                                                >
                                                    <i className="bi bi-envelope-fill"></i>{" "}Contact
                                                </a>
                                            } />
                                        </Col>
                                        <Col className="col-6 h1 m-0 p-0 text-center text-success fw-bold">
                                            <Donate as={
                                                handleClick => <a
                                                    target="_blank"
                                                    href="https://ko-fi.com/flagsofasexuality"
                                                    onClick={() => toggleMobileMenu(false)}
                                                    className="text-decoration-none link-success"
                                                    role="menuitem"
                                                >
                                                    <i className="bi bi-currency-dollar fw-bold"></i>{" "}Donate
                                                </a>
                                            } />
                                        </Col>
                                    </Row>
                                </Container>
                            </span>
                        </ul>
                    </nav>
                </Col>
            </Row>
        </Container>
    );
}