import { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from 'formik';
import Config from "../../config/Config"

function ContactModal() {

}
export default function Contact({ as }) {
    async function send(values, formik) {
        let response;
        try {
            response = await fetch(
                `${Config.config.api_url}/message`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                }
            )
        } catch (_) {
            response = { ok: false };
        }

        if (response.ok) {
            setShowing({ modal: true, error: false, success: true, id: Math.random() });
            formik.resetForm();
        } else {
            setShowing({ modal: true, error: true, success: false, id: Math.random() });
        }
    }

    const [isShowing, setShowing] = useState({ modal: false, error: false, success: false });

    return <>
        {as(() => setShowing({ modal: true, error: false, success: false }))}
        <Modal
            show={isShowing.modal}
            onHide={() => setShowing({ modal: false, error: false, success: false })}
            fullscreen="lg-down"
            aria-labelledby="foa-form-contact-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="foa-form-contact-title">Contact</Modal.Title>
            </Modal.Header>

            <Formik initialValues={{ category: "", email: "", message: "" }} onSubmit={send}>
                <FormikForm aria-labelled-by="foa-form-contact-title">
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label id="foa-form-contact-field-category-title">Category</Form.Label>
                            <Field name="category" as="select" className="form-select" aria-labelledby="foa-form-contact-field-category-title" aria-required="true" required>
                                <option value="">Please choose a category.</option>
                                <option>General Comment</option>
                                <option>Correction or Critique</option>
                            </Field>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label id="foa-form-contact-field-email-title">Email Address</Form.Label>
                            <Field
                                as={Form.Control}
                                name="email"
                                type="email"
                                autocomplete="email"
                                aria-labelledby="foa-form-contact-field-email-title"
                                aria-describedby="foa-form-contact-field-email-description"
                                aria-required="false"
                            />
                            <Form.Text id="foa-form-contact-field-email-description">Optional. Provide an email address if your request requires a response.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label id="foa-form-contact-field-message-title">Message</Form.Label>
                            <Field as="textarea" className="form-control" name="message" aria-labelledby="foa-form-contact-field-message-title" aria-required="true" required />
                        </Form.Group>
                        <span className="mt-3 fw-bold" role="status" id="foa-form-contact-alert" key={isShowing.id}>
                            {isShowing.success ? <span className="text-success">Message sent. Thank you!</span> : ""}
                            {isShowing.error ? <span className="text-danger">Something went wrong. Please try again later.</span> : ""}
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </FormikForm>
            </Formik>
        </Modal>
    </>;
}