import { Col, Container, Row, Button, OverlayTrigger, Tooltip, Toast } from "react-bootstrap";
import Menu from "./components/Menu";
import Identity from "./components/Identity";
import React, { useState } from "react";
import config from "./config/identities.yaml";
import HtmlReactParse, { attributesToProps } from 'html-react-parser';
import Introduction from "./components/Introduction";
import Pagination from "./components/Pagination";
import Contact from "./components/cta/Contact";
import Donate from "./components/cta/Donate";
import Share from "./components/cta/Share";
import Flag from "./components/Flag";

const replace = node => {
    if (node.type !== "tag") {
        return <></>;
    }

    if (!node.attribs) {
        node.attribs = {};
    }

    if (!node.attribs.role) {
        node.attribs.role = "presentation";
    }

    return React.createElement(
        node.name,
        attributesToProps(node.attribs),
        node.children
    );
};
const identities = config.map(identity => <Identity
    name={identity.name}
    flag={<Flag name={identity.name} description={identity.svg_desc} svg={HtmlReactParse(identity.svg, { replace: replace })} />}
    description={HtmlReactParse(identity.description)}
/>);


const pages = [<Introduction />].concat(identities);
const page_names = ["introduction"].concat(identities.map(i => i.props.name));
const page_titles = ["learn all the shades of asexuality"].concat(identities.map(i => i.props.name));

export default function App() {
    function setPageTitle(title) {
        document.title = `flags of asexuality | ${title}`;
    }
    function changePage(page_name) {
        let idx = page_names.indexOf(page_name)
        if (idx !== -1) {
            setActivePageIdx(idx);
            setPageTitle(page_titles[idx]);
        }
    }

    const [copied, setCopied] = useState(false);
    const target = new URLSearchParams(window.location.search).get("page");
    const [activePageIdx, setActivePageIdx] = useState(
        target && page_names.includes(target) ? page_names.indexOf(target) : 0
    );
    setPageTitle(page_titles[activePageIdx]);

    return (
        <Container className="d-flex flex-column vh-100 vw-100" fluid>
            <Row>
                <Col>
                    <header role="banner">
                        <Menu pages={page_names} activePageIdx={activePageIdx} handlePageSelect={changePage} />
                    </header>
                </Col>
            </Row>
            <Row className="flex-grow-1 d-flex align-items-start align-items-lg-center" style={{ overflowY: "auto" }}>
                <Col>
                    <main role="main">
                        <section role="region" title={page_names[activePageIdx]} aria-label={page_names[activePageIdx]}>
                            {pages[activePageIdx]}
                        </section>
                    </main>
                </Col>
            </Row>
            <Row className="d-block d-lg-none border-top py-2">
                <Col>
                    <Pagination activePageIdx={activePageIdx} pages={page_names} handlePageSelect={changePage} />
                </Col>
            </Row>
            <div className="d-none d-lg-flex flex-column position-fixed bottom-0 end-0 me-3 mb-3" style={{ maxWidth: "min-content" }}>
                <Share
                    target={page_names[activePageIdx]}
                    as={
                        handleClick =>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip role={copied ? "alert" : "none"}>{copied ? "Link copied." : "Share"}</Tooltip>}
                            >
                                <Button
                                    variant="primary"
                                    size="lg"
                                    className="rounded-circle w-5 h-5 foa-cta"
                                    aria-label="Share"
                                    onClick={() => { handleClick(); setCopied(true); }}
                                    onBlur={() => setCopied(false)}
                                    onMouseOut={() => setCopied(false)}
                                >
                                    <i className="bi bi-share-fill" style={{ color: "white" }}></i>
                                </Button>
                            </OverlayTrigger>
                    }
                />
                <span className="my-2"></span>
                <Contact as={
                    handleClick =>
                        <>
                            <OverlayTrigger placement="left" overlay={<Tooltip>Contact</Tooltip>}>
                                <Button
                                    variant="secondary"
                                    size="lg"
                                    className="rounded-circle w-5 h-5 foa-cta"
                                    aria-label="Contact"
                                    onClick={handleClick}
                                >
                                    <i className="bi bi-envelope-fill"></i>
                                </Button>
                            </OverlayTrigger>
                        </>
                } />
                <span className="my-2"></span>
                <Donate as={
                    handleClick =>
                        <>
                            <OverlayTrigger placement="left" overlay={<Tooltip>Donate</Tooltip>}>
                                <a
                                    target="_blank"
                                    href="https://ko-fi.com/flagsofasexuality"
                                    aria-label="Donate"
                                    className="btn btn-lg btn-success rounded-circle w-5 h-5 foa-cta"
                                    onClick={handleClick}
                                >
                                    <i className="bi bi-currency-dollar fw-bold"></i>
                                </a>
                            </OverlayTrigger>
                        </>
                } />
            </div>
        </Container>
    );
}