import { Row, Col, Container } from "react-bootstrap"
import Donate from "./cta/Donate";
import Contact from "./cta/Contact";

const contact_cta = <Contact
as={
    handleClick =>
        <a href="#" onClick={handleClick}>Feel free to let me know</a>
}>

</Contact>;
const donate_cta = <Donate
    as={
        handleClick => <a target="_blank" href="https://ko-fi.com/flagsofasexuality" onClick={handleClick}>I accept donations</a>
    }
>
</Donate>;
const text = <p>
    <br />
    Hello. I am <a href="https://twitter.com/aDifferentRealm" target="_blank">@aDifferentRealm</a>; some of you may already know me from <a href="https://www.youtube.com/watch?v=zW29J3nxjis" target="_blank">Anthony Padilla's video on asexuality.</a>
    <br /><br />
    Asexuality is the lack of sexual attraction towards other individuals. This is a good starting point definition, but it is admittedly a loaded term, used as an umbrella for a group of wonderfully diverse people and nuanced identities. This site exists to shed light on these subgroups.
    <br /><br />
    I hope this site helps many discover something new about themselves, reaffirm the validity of others already in the know, and further educate current and future allies.
    <br /><br />
    As you read through the various identities listed on this site, please keep in mind that - unless otherwise specified - asexual identities only address conditions in which sexual attraction is or is not present. Notably, asexual identities do not concern themselves with either relationships (romantic or otherwise) or the sexual dynamics of relationships. There is an entire world of other terms to address these things that can be used in conjunction with asexual identities, including <a href="http://wiki.asexuality.org/Romantic_attraction" target="_blank">romantic identities</a>, <a href="http://wiki.asexuality.org/Indifferent" target="_blank">indifference</a> and <a href="http://wiki.asexuality.org/Repulsed" target="_blank">sex repulsion</a>.
    <br /><br />
    I've learned a lot from making this website, but there's always more opportunity to learn! {contact_cta} if there is something wrong with the site: visually, functionally, or information-wise!
    <br /><br />
    If you like my work and are feeling generous, {donate_cta}. Donations go first and foremost towards hosting costs.
</p>;

export default function Introduction() {
    return (
        <Container fluid>
            <Row>
                <Col xs={{ offset: 1, span: 10 }} lg={{ offset: 3, span: 6 }}>
                    {text}
                </Col>
            </Row>
        </Container>
    );
}