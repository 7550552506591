import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Share from "./cta/Share";

export default function Pagination({ pages, activePageIdx, handlePageSelect }) {
    const previous = activePageIdx !== 0 ? pages[activePageIdx - 1] : null;
    const next = activePageIdx + 1 !== pages.length ? pages[activePageIdx + 1] : null;

    return (
        <Container className="p-0 m-0" fluid>
            <Row className="p-0 m-0">
                <Col className="p-0">
                    <nav role="navigation" aria-label="Quick navigation.">
                        <ul role="menu" className="d-flex list-inline m-0 p-0">
                            <li className="list-inline-item col-4 m-0" role={previous ? "menuitem" : "none"}>
                                <a
                                    href="#"
                                    onClick={() => handlePageSelect(previous)}
                                    className={[
                                        "text-decoration-none text-muted",
                                        previous ? "" : "d-none"
                                    ].join(" ")}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <i className="bi bi-arrow-left"></i><br /><em>{previous}</em>
                                </a>
                            </li>
                            <li className="list-inline-item text-center col-4 m-0" role="menuitem">
                                <Share
                                    target={pages[activePageIdx]}
                                    as={
                                        handleClick =>
                                            <OverlayTrigger
                                                placement="top"
                                                trigger="click"
                                                overlay={<Tooltip role="status">Link copied.</Tooltip>}
                                                onToggle={show => {if (show) setTimeout(() => { document.getElementById("foa-pagination-share").click() }, 3000)}}
                                            >
                                                <a
                                                    href="#"
                                                    onClick={handleClick}
                                                    className="text-decoration-none link-primary"
                                                    id="foa-pagination-share"
                                                >
                                                    <i className="bi bi-share-fill"></i><br />
                                                    share
                                                </a>
                                            </OverlayTrigger>
                                    }
                                />
                            </li>
                            <li className="list-inline-item col-4 text-end m-0" role={next ? "menuitem" : "none"}>
                                <a
                                    href="#"
                                    onClick={() => handlePageSelect(next)}
                                    className={[
                                        "text-decoration-none text-muted",
                                        next ? "" : "d-none"
                                    ].join(" ")}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <i className="bi bi-arrow-right"></i><br /><em>{next}</em>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </Col>
            </Row>
        </Container>
    )
}